<template>
  <booking-schedule-history-table
    ref="table"
    :setting-columns="columns"
    :setting-apis="apis">
    <template v-slot:cell-is_draft_reserved="props">
      <td class="v-align-middle text-center">
        <div class="ml-4 checkbox check-success">
          <input type="checkbox" disabled="disabled" :checked="props.row.is_draft_reserved" id="check1">
          <label for="check1"></label>
        </div>
      </td>
    </template>
    <template v-slot:cell-is_final_reserved="props">
      <td class="v-align-middle text-center">
        <div class="ml-4 checkbox check-success">
          <input type="checkbox" disabled="disabled" :checked="props.row.is_final_reserved" id="check2">
          <label for="check2"></label>
        </div>
      </td>
    </template>
  </booking-schedule-history-table>
</template>
<script>
import BookingScheduleHistoryTable from "@components/admin/bookings/_partials/BookingScheduleHistoryTable";

export default {
  components: {BookingScheduleHistoryTable},
  data() {
    return {
      apis: {
        list: {
          endpoint: this.$consts.API.ADMIN.BOOKING_HISTORY
        },
      },
      columns: [
        {name: 'schedule_date', label: this.$t('booking.schedule_date'), class: 'min-w-100'},
        {name: 'is_draft_reserved', label: this.$t('booking.is_draft_reserved'), class: 'min-w-100'},
        {name: 'is_final_reserved', label: this.$t('booking.is_final_reserved'), class: 'min-w-100'},
        {name: 'reserved_content', label: this.$t('booking.reserved_content'), class: 'min-w-100 pre-content'},
        {name: 'admin_name', label: this.$t('booking.admin_name'), class: 'min-w-100'},
        {name: 'created_at', label: this.$t('booking.s_history_at'), class: 'min-w-100'},
      ]
    }
  },
  methods: {
    async onShow(bookingId) {
      this.$refs.table.entries = [];
      await this.$refs.table.getEntries(bookingId)
      setTimeout(() => {
        $.each($('.app-cell-tooltip'), (index, el) => {
          if (el.offsetWidth < el.scrollWidth) {
            $(el).tooltip({boundary: 'window'})
          }
        })
      }, 200)
    }
  }
}
</script>

<style>
.pre-content p{
  white-space: pre !important;
}
</style>
