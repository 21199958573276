var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:" container-fluid container-fixed-lg bg-white app-table-container"},[_c('div',{staticClass:"card card-transparent"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"dataTables_wrapper no-footer bg-white"},[_c('div',{staticClass:"table-responsive sm-m-b-15",staticStyle:{"max-height":"500px"}},[_c('table',{class:[_vm.entries.length ? '' : 'table-empty-data', 'table dataTable app-table no-footer']},[_c('thead',[_c('tr',[_vm._l((_vm.columns),function(column){return [(column.sortable)?_c('th',{class:[
                  column.class,
                  _vm.pagination.sortBy === column.name ? (_vm.pagination.descending ? 'sorting_desc' : 'sorting_asc') : 'sorting',
                   'text-center',
                   column.fitSize ? 'cell-fit-content' : ''
                   ],on:{"click":function($event){return _vm.toggleSortBy(column.name)}}},[_vm._v(" "+_vm._s(column.label)+" ")]):_c('th',{class:[
                      column.class,
                     'text-center', column.fitSize ? 'cell-fit-content' : '']},[_vm._v(" "+_vm._s(column.label)+" ")])]})],2)]),_c('tbody',[(!_vm.entries.length)?_c('tr',[_c('td',{attrs:{"colspan":"100"}},[(_vm.loading)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.loading'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.list empty'))+" ")])])]):_vm._e(),_vm._l((_vm.entries),function(entry){return _c('tr',[_vm._l((_vm.columns),function(column){return _vm._t('cell-' + column.name,function(){return [_c('td',{class:['v-align-middle', column.class]},[(column.computedHtml)?_c('p',{staticClass:"app-table-p app-cell-tooltip"},[_c('span',{domProps:{"innerHTML":_vm._s(column.computedHtml(entry))}})]):_c('p',{staticClass:"app-table-p app-cell-tooltip",attrs:{"data-original-title":column.computed ? column.computed(entry) : _vm._.get(entry, column.name)}},[_c('span',[_vm._v(_vm._s(column.computed ? column.computed(entry) : _vm._.get(entry, column.name)))])])])]},{"row":entry})})],2)})],2)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }