<template>
  <div class=" container-fluid container-fixed-lg bg-white app-table-container">
    <div class="card card-transparent">
      <div class="card-body">
        <div class="dataTables_wrapper no-footer bg-white">
          <div class="table-responsive sm-m-b-15" style="max-height: 500px">
            <table :class="[entries.length ? '' : 'table-empty-data', 'table dataTable app-table no-footer']">
              <thead>
              <tr>
                <template v-for="column in columns">
                  <th v-if="column.sortable" @click="toggleSortBy(column.name)"
                      :class="[
                    column.class,
                    pagination.sortBy === column.name ? (pagination.descending ? 'sorting_desc' : 'sorting_asc') : 'sorting',
                     'text-center',
                     column.fitSize ? 'cell-fit-content' : ''
                     ]">
                    {{ column.label }}
                  </th>
                  <th v-else
                      :class="[
                        column.class,
                       'text-center', column.fitSize ? 'cell-fit-content' : '']">
                    {{ column.label }}
                  </th>
                </template>
              </tr>
              </thead>
              <tbody>
              <tr v-if="!entries.length">
                <td colspan="100">
                  <span v-if="loading">
                    {{ $t('common.loading') }}
                  </span>
                  <span v-else>
                    {{ $t('common.list empty') }}
                  </span>
                </td>
              </tr>
              <tr v-for="entry in entries">
                <slot v-for="column in columns" :name="'cell-' + column.name" :row="entry">
                  <td :class="['v-align-middle', column.class]">
                    <p v-if="column.computedHtml" class="app-table-p app-cell-tooltip">
                      <span v-html="column.computedHtml(entry)"></span>
                    </p>
                    <p v-else
                       :data-original-title="column.computed ? column.computed(entry) : _.get(entry, column.name)"
                       class="app-table-p app-cell-tooltip">
                      <span>{{ column.computed ? column.computed(entry) : _.get(entry, column.name) }}</span>
                    </p>
                  </td>
                </slot>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    settingColumns: Array,
    tableName: String,
    settingApis: Object
  },
  data() {
    const columns = this.settingColumns.map(val => {
      return {
        sortable: false,
        editable: true,
        label: val.label === undefined ? val.name.charAt(0).toUpperCase() + val.name.slice(1) : val.label,
        ...val
      }
    })
    return {
      columns: columns,
      entriesRes: {},
      entries: [],
      loading: true,
    }
  },
  methods: {
    async getEntries(bookingId) {
      let endpoint = this.settingApis.list.endpoint;
      let apiParams = this.settingApis.list.params;
      if (!apiParams) {
        apiParams = {}
      }
      const res = await this.$request.get(endpoint(bookingId), {...apiParams})
      if (!res.hasErrors()) {
        this.entriesRes = _.cloneDeep(res);
        this.entries = res.data.data.map((entry, index) => {
          return {
            ...entry
          }
        })
      }
      this.$nextTick(() => {
        $.each($('.app-cell-tooltip'), (index, el) => {
          if (el.offsetWidth < el.scrollWidth) {
            $(el).tooltip({boundary: 'window'})
          }
        })
      });
      this.loading = false;
    },
  }
};
</script>
