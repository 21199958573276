<template>
  <div>
    <Modal modal-class="slide-up" ref="modal_update_batch_payment" size="lg" :on-hide="hideModalUpdatePayment"
           :modal-header-title="$t('booking.update_batch_payment')">
      <template v-slot:container>
        <ValidationObserver ref="form_update_payment" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(updateBatchPayment)">
            <div class="modal-body">
              <label class="text-complete app-text-bold mb-3" style="font-size: 10px !important;">
                <div>変更したいステータスを選択してください。</div>
                <div>※ 一度変更したら、元に戻れませんので、ご注意ください。</div>
              </label>
              <AppSelect name="payment_status" :label="$t('booking.payment_status')" type="string" rules="required"
                         :options-data="meta.payment_status_select"
                         v-model="batch_payment_info.payment_status"/>
              <AppDatePicker name="payment_date" :label="$t('booking.payment_date')" rules="required"
                             v-model="batch_payment_info.payment_date"/>
              <AppSelect name="payment_confirm_admin_id" :label="$t('booking.payment_confirm_admin_id')"
                         rules="required"
                         :options-data="meta.admin_select"
                         v-model="batch_payment_info.payment_confirm_admin_id"/>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('booking.update_payment') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <Modal modal-class="slide-up" ref="modal_choose_tour_template" size="lg"
           :modal-header-title="$t('booking.choose_tour')">
      <template v-slot:container>
        <tour-select-list ref="modal_choose_tour_template_table" :tourSelectCallback="updateTourSelect"/>
        <div class="modal-footer">
          <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                  data-dismiss="modal">
            {{ $t('common.back') }}
          </button>
        </div>
      </template>
    </Modal>
    <Modal modal-class="slide-up" ref="modal_list_schedule_history" size="lg"
           :modal-header-title="$t('booking.schedule_history')">
      <template v-slot:container>
        <booking-schedule-history-list ref="modal_booking_schedule_history_table"/>
        <div class="modal-footer">
          <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                  data-dismiss="modal">
            {{ $t('common.back') }}
          </button>
        </div>
      </template>
    </Modal>
    <basic-table ref="table"
                 :table-name="tableName"
                 :setting-columns="columns"
                 :actions="actions"
                 :filters.sync="filters"
                 :search="search"
                 @resetAllSearch="onResetAllSearch"
                 :search-entries-call-back="searchBookingCallback"
                 :setting-apis="apis">
      <template v-slot:header_table>
        <button v-if="selectedEntries.length" class="btn btn-complete app-text"
                @click="$refs.modal_update_batch_payment.show()">
          {{ $t('booking.update_batch_payment') }}
        </button>
      </template>
      <template v-slot:header_right_table>
        <button v-if="$refs.table && $refs.table.entries.length" @click="exportBookingCsv"
                class="btn btn-complete app-text mr-1"> {{
            $t('booking.export_csv')
          }}
        </button>
      </template>
      <template v-slot:thead-checkbox="props">
        <th class="v-align-middle text-center">
          <input
            v-if="$refs.table && $refs.table.entries.filter(entry => canUpdatePayment(entry.payment_status)).length"
            class="mt-2" type="checkbox" v-model="checkall"/>
        </th>
      </template>
      <template v-slot:cell-member_name="props">
        <td class="v-align-middle  min-w-100">
          <router-link
            target="_blank"
            :to="{name: $consts.ROUTES.ADMIN.MEMBERS_UPDATE, params: {id: props.row.member_id}}"
            class="a-link-cell"
          >
            <p :data-original-title="getEntryMemberName(props.row)" class="app-table-p app-cell-tooltip">
              {{ getEntryMemberName(props.row) }}
            </p>
          </router-link>
        </td>
      </template>
      <template v-slot:cell-checkbox="props">
        <td class="v-align-middle">
          <input v-if="canUpdatePayment(props.row.payment_status)"
                 class="mt-2" type="checkbox"
                 @change="rowCheckChangeEvent"
                 :value="props.row.id"
                 v-model="selectedEntries"/>
        </td>
      </template>
      <template v-slot:cell-tour_template_setting="props">
        <template v-if="props.row.tour_order">
          <td class="v-align-middle">
              <p :data-original-title="props.row.tour_order.tourname_name" class="app-table-p app-cell-tooltip">
                {{ props.row.tour_order.tourname_name }}</p>
          </td>
        </template>
        <template v-else>
          <td class="v-align-middle text-center">
<!--            <button-->
<!--              @click="$refs.modal_choose_tour_template_table.onShow() ;$refs.modal_choose_tour_template.show(); bookingTourSettingSelected = props.row"-->
<!--              class="btn btn-tool"> {{-->
<!--                $t('booking.choose_tour')-->
<!--              }}-->
<!--            </button>-->
          </td>
        </template>
      </template>
      <template v-slot:cell-edit_tour="props">
        <td class="v-align-middle min-w-100">
          <router-link v-if="props.row.b_tour_order_id" target="_blank"
                       style="min-width: 25px !important;"
                       :to="{name: $consts.ROUTES.ADMIN.BOOKING_UPDATE_TOUR, params: {id: props.row.b_tour_order_id}}"
                       class="btn btn-tool" data-toggle="tooltip">
            {{ $t('booking.setting_tour') }}
          </router-link>
        </td>
      </template>
      <template v-slot:cell-schedule_history="props">
        <td class="v-align-middle min-w-80">
          <button v-if="props.row.tour_order"
                  style="min-width: 20px !important;"
                  @click="$refs.modal_booking_schedule_history_table.onShow(props.row.tour_order.id);
                  $refs.modal_list_schedule_history.show();"
                  class="btn btn-tool"> {{
              $t('booking.schedule_history_detail')
            }}
          </button>
        </td>
      </template>
      <template v-slot:cell-tour_public_url="props">
        <template v-if="props.row.tour_order">
          <td class="v-align-middle">
            <a target="_blank" :href="props.row.tour_order.public_url"
               class="a-link-cell">
              <button type="submit" class="btn  btn-complete  m-r-10"> {{ $t('booking.preview') }} </button>
            </a>
          </td>
        </template>
        <template v-else>
          <td class="v-align-middle text-center">
            <!--            <button-->
            <!--              @click="$refs.modal_choose_tour_template_table.onShow() ;$refs.modal_choose_tour_template.show(); bookingTourSettingSelected = props.row"-->
            <!--              class="btn btn-tool"> {{-->
            <!--                $t('booking.choose_tour')-->
            <!--              }}-->
            <!--            </button>-->
          </td>
        </template>
      </template>
    </basic-table>
  </div>
</template>
<script>
import BasicTable from "@components/_common/List/BasicTable";
import B_CONST from "@components/admin/bookings/constants";
import TourSelectList from "@components/admin/bookings/_partials/TourSelectList";
import BookingScheduleHistoryList from "@components/admin/bookings/_partials/BookingScheduleHistoryList";

export default {
  components: {
    BookingScheduleHistoryList,
    TourSelectList,
    'basic-table': BasicTable
  },
  data() {
    const batch_payment_info_init = {
      payment_status: 1,
      payment_date: moment().format('YYYY/MM/DD'),
      payment_confirm_admin_id: this.$auth.user().id,
    }
    return {
      bookingTourSettingSelected: {},
      checkall: false,
      meta: {
        payment_status_select: B_CONST.PAYMENT_STATUS_SELECT,
        admins: [],
      },
      selectedEntries: [],
      batch_payment_info: batch_payment_info_init,
      batch_payment_info_init: _.cloneDeep(batch_payment_info_init),
      tableName: this.$t('users.user list'),
      apis: {
        list: {
          endpoint: this.$consts.API.ADMIN.BOOKING_LIST,
        },
        delete: {
          endpoint: this.$consts.API.ADMIN.BOOKING_DELETE,
        },
      },
      actions: {
        deleteEntry: true,
        editEntry: entry => this.$router.push({name: this.$consts.ROUTES.ADMIN.BOOKING_UPDATE, params: {id: entry.id}}),
        createEntry: () => this.$router.pushByName(this.$consts.ROUTES.ADMIN.BOOKING_CREATE),
      },
      search: {
        reset: true,
      },
      filters: {
        member_name_multi_like: {
          label: this.$t('booking.member_name'),
          name: 'member_name',
          type: 'multi_like',
          uiType: 'multi_select',
          ui_options: {
            // tags: true,
            closeOnSelect: true,
          },
          boundaryClass: 'col-md-4 col-sm-12'
        },
        email_like: {
          label: this.$t('booking.member_email'),
          placeholder: this.$t('booking.member_email'),
          name: 'member_email',
          type: 'like',
          uiType: 'input',
          boundaryClass: 'col-md-4 col-sm-12'
        },
        phone_like: {
          label: this.$t('booking.member_phone'),
          placeholder: this.$t('booking.member_phone'),
          name: 'member_phone',
          type: 'like',
          uiType: 'input',
          boundaryClass: 'col-md-4 col-sm-12'
        },
        status_equal: {
          label: this.$t('booking.status'),
          name: 'status',
          type: 'equal',
          uiType: 'select',
          options: [],
          boundaryClass: 'col-md-4'
        },
        admin_id_equal: {
          label: this.$t('booking.admin_name'),
          name: 'admin_id',
          type: 'equal',
          uiType: 'select',
          options: [],
          boundaryClass: 'col-md-4'
        },
        prefecture_id_equal: {
          label: this.$t('booking.prefecture'),
          name: 'prefecture_id',
          type: 'equal',
          uiType: 'select',
          options: [],
          boundaryClass: 'col-md-4'
        },
        city_id_equal: {
          label: this.$t('booking.cities'),
          name: 'city_id',
          type: 'equal',
          uiType: 'select',
          options: [],
          boundaryClass: 'col-md-4'
        },
      },
      columns: [
        {name: 'checkbox', label: ""},
        {name: 'id', label: this.$t('booking.id'), class: 'min-w-80 text-center'},
        {name: 'member_name', label: this.$t('booking.member_name')},

        {name: 'admin.name', label: this.$t('booking.admin_name'), class: 'min-w-100'},
        {name: 'tour_template_setting', label: this.$t('booking.tour_name'), class: 'min-w-90'},
        {
          name: 'booking_date', label: this.$t('booking.booking_date'),
          class: 'min-w-100',
          computed: entry => {
            var split = (entry.start_date || entry.end_date) ? ' ~ ' : '';
            return (entry.start_date ? entry.start_date : '') + split + (entry.end_date ? entry.end_date : '')
          }
        },
        {
          name: 'people_num', label: this.$t('booking.people_num'), class: 'min-w-100', computedHtml: entry => {
            var firstLine = entry.adult_count_tour !== null ? `<div>大人${entry.adult_count_tour}名・</div>` : '';
            var secondLine = entry.child_count_tour !== null ? `<div>子供${entry.child_count_tour}名</div>` : '';
            return firstLine + secondLine
          }
        },
        {
          name: 'status', label: this.$t('booking.status'),
          class: 'min-w-120 text-center',
          computed: entry => entry.status ? B_CONST.STATUS_MAP[entry.status] : entry.status
        },
        {
          name: 'tour_type', label: this.$t('tour_order_template.tour_type'),
          class: 'min-w-120 text-center',
          computedHtml: entry => {
            let type = '';
            let tourOrder = entry.tour_order;
            if (tourOrder) {
              if (tourOrder.type == 2) {
                type = this.$t('tour_order_template.furusato_type');
              } else if (tourOrder.type == 1) {
                type= this.$t('tour_order_template.normal_type');
              }
            }
            return type;
          }
        },
        {
          name: 'tour_public_url', label: this.$t('booking.preview'),
          class: 'min-w-120 text-center',
        },
        // {
        //   name: 'discuss_method',
        //   label: this.$t('booking.discuss_method'),
        //   class: 'min-w-100 text-center',
        //   computed: entry => entry.discuss_method ? B_CONST.DISCUSS_METHOD_MAP[entry.discuss_method] : ''
        // },
        // {
        //   name: 'discuss_time', label: this.$t('booking.discuss_time'), class: 'min-w-150',
        //   computedHtml: entry => {
        //     return `<div>1. ${entry.discuss_time[0].date} ${B_CONST.DISCUSS_TIME_MAP[entry.discuss_time[0].time]}</div>` +
        //       `<div>2. ${entry.discuss_time[1].date} ${B_CONST.DISCUSS_TIME_MAP[entry.discuss_time[1].time]}</div>` +
        //       `<div>3. ${entry.discuss_time[2].date} ${B_CONST.DISCUSS_TIME_MAP[entry.discuss_time[2].time]}</div>`;
        //   }
        // },
        // {name: 'hearing_area', label: this.$t('booking.hearing_area'), class: 'min-w-100'},
        //
        //
        // {name: 'edit_tour', label: this.$t('booking.edit_tour'), class: 'min-w-100'},
        // {name: 'application_deadline', label: this.$t('booking.application_deadline'), class: 'min-w-100'},
        // {
        //   name: 'proposal_status', label: this.$t('booking.proposal_status'), class: 'min-w-120 text-center',
        //   computed: entry => entry.proposal_status ? B_CONST.PROPOSAL_STATUS_MAP[entry.proposal_status] : ''
        // },
        // {
        //   name: 'invoice_status', label: this.$t('booking.invoice_status'), class: 'min-w-100 text-center',
        //   computed: entry => entry.invoice_status ? B_CONST.INVOICE_STATUS_MAP[entry.invoice_status] : ''
        // },
        // {
        //   name: 'tour_order.price', label: this.$t('booking.tour_price'), class: 'min-w-100',
        //   computed: entry => entry.tour_order ? this.formatPriceJa(entry.tour_order.price) : ''
        // },
        // {name: 'payment_deadline', label: this.$t('booking.payment_deadline'), class: 'min-w-100 text-center'},
        // {
        //   name: 'payment_status', label: this.$t('booking.payment_status'), class: 'min-w-120 text-center',
        //   computed: entry => entry.payment_status ? B_CONST.PAYMENT_STATUS_MAP[entry.payment_status] : ''
        // },
        // {name: 'schedule_history', label: this.$t('booking.schedule_history')}
      ]
    }
  },
  mounted() {
    this.filters.status_equal.options = B_CONST.STATUS_SELECT
    this.$request.get(this.$consts.API.ADMIN.UI_HELPER_BOOKING).then(res => {
      if (!res.hasErrors()) {
        this.filters.admin_id_equal.options = _.cloneDeep(res.data.admins);
        this.filters.member_name_multi_like.options = _.cloneDeep(res.data.members_by_name);
        this.meta.admin_select = _.cloneDeep(res.data.admins);
        this.meta = _.cloneDeep(this.meta)
      }
    })
    this.$request.get(this.$consts.API.ADMIN.TOUR_PREFECTURES).then(res => {
      if (!res.hasErrors()) {
        this.filters.prefecture_id_equal.options = _.cloneDeep(res.data.data);
        this.filters.prefecture_id_equal.options.push({
          id: 0,
          name: this.$t('common.select_all'),
        });
      }
    })
  },
  watch: {
    checkall: {
      handler: function (after, before) {
        if (after == true) {
          this.selectedEntries = this.$refs.table.entries.filter(entry => this.canUpdatePayment(entry.payment_status))
            .map(entry => entry.id)
        } else {
          this.selectedEntries = [];
        }
      }
    },
    'filters.prefecture_id_equal.value': function (after, before) {
      let params = {};
      params[`filters[prefecture_id]`] = after ;
      if (after !== null) {
        this.$request.get(this.$consts.API.ADMIN.TOUR_PREFECTURES_CITIES, params).then(res => {
          if (!res.hasErrors()) {
            this.filters.city_id_equal.options = _.cloneDeep(res.data.data[0] ? res.data.data[0].cities : []);
            this.filters.city_id_equal.options.push({
              id: 0,
              name: this.$t('common.select_all'),
            })
          }
        })
      }
    }
  },
  methods: {
    updateBatchPayment() {
      this.$request.post(this.$consts.API.ADMIN.BOOKING_UPDATE_PAYMENT, {
        ids: this.selectedEntries,
        ...this.batch_payment_info
      }).then(res => {
        if (!res.hasErrors()) {
          this.$refs.modal_update_batch_payment.hide();
          this.$refs.form_update_payment.reset();
          this.batch_payment_info = {
            payment_status: 1,
            payment_date: moment().format('YYYY/MM/DD'),
            payment_confirm_admin_id: this.$auth.user().id,
          };
          this.$refs.table.getEntries();
          this.$appNotice.success(this.$t('common.msg update ok'));
          this.selectedEntries = [];
        } else {
          this.$appNotice.error()
        }
      })
    },
    updateTourSelect(tourTemplateObj) {
      this.$request.patch(this.$consts.API.ADMIN.BOOKING_UPDATE(this.bookingTourSettingSelected.id), {
        tour_order_template_id: tourTemplateObj.id
      }).then(res => {
        if (!res.hasErrors()) {
          this.$refs.modal_choose_tour_template.hide();
          this.$refs.table.getEntries();
          this.$appNotice.success(this.$t('common.msg update ok'));
        } else {
          this.$appNotice.error()
        }
      })
    },
    exportBookingCsv() {
      var params = {}
      _.forEach(this.filters, (filter) => {
        if (filter.value && filter.value.toString().length) {
          params[`filters[${filter.name}][${filter.type}]`] = filter.value
        }
      })
      this.$request.get(this.$consts.API.ADMIN.BOOKING_EXPORT, {...params}).then(res => {
        if (!res.hasErrors()) {
          this.downloadFromUrl(res.data.url)
        } else {
          this.$appNotice.error();
        }
      })
    },
    canUpdatePayment(paymentStatus) {
      return !paymentStatus || [B_CONST.PAYMENT_STATUS_NOT_PAYMENT,
        B_CONST.PAYMENT_STATUS_PAYMENT_FAIL,
        B_CONST.PAYMENT_STATUS_PAYMENT_TRANSFER_WAITING,
        B_CONST.PAYMENT_STATUS_PAYMENT_ERROR].includes(parseInt(paymentStatus))
    },
    getEntryMemberName(entry) {
      const member = entry.member;
      let name = (member.sei ? member.sei : '') + '　' + (member.mei ? member.mei : '');
      return $.trim(name);
    },
    hideModalUpdatePayment() {
      this.$refs.form_update_payment.reset()
      this.batch_payment_info = _.cloneDeep(this.batch_payment_info_init)
    },
    rowCheckChangeEvent() {
      if (this.selectedEntries.length === 0) {
        this.checkall = false
      } else if (this.$refs.table.entries.filter(entry => this.canUpdatePayment(entry.payment_status)).length === this.selectedEntries.length) {
        this.checkall = true;
      }
    },
    onResetAllSearch() {
      this.selectedEntries = [];
      this.checkall = false
    },
    searchBookingCallback(entries) {
      this.checkall = false;
      this.selectedEntries = [];
    }
  }
}
</script>
