<template>
  <div class=" container-fluid container-fixed-lg bg-white app-table-container">
    <div class="card card-transparent">
      <div class="card-header search-container">
        <div class="">
          <ValidationObserver v-slot="{ passes, valid, validated }">
            <form novalidate autocomplete="off" @submit.prevent="passes(searchHandle)">
              <div class="row justify-content-start">
                <div class="col-md-5">
                  <template v-for="(filter, index) in filters">
                    <template v-if="filter.uiType === 'multi_select'">
                      <AppSelectMulti
                        :options="_.merge(filter.ui_options, {containerCssClass: 'z-index-0', placeholder: filter.label})"
                        input-style="normal" :name="filter.name"
                        :label="filter.label"
                        label-class="text-complete"
                        :onChange="filter.changeEvent ? filter.changeEvent : null"
                        :options-data="filter.options"
                        v-model="filters[index].value"/>
                    </template>
                  </template>
                </div>
                <div class="m-t-auto m-b-15">
                  <button :disabled="!valid && validated" class="btn btn-complete m-l-10 m-r-10"
                          type="submit">
                    {{ $t('common.search') }}
                  </button>
                  <button v-if="search.reset"
                          @click="resetAllSearch()"
                          class="btn btn-default m-r-10" type="button">
                    {{ $t('common.reset') }}
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <div class="card-header d-flex justify-content-between">
        <div class="card-title table-data-info">
          <div class="dataTables_info app-text" style="text-transform: lowercase" role="status" aria-live="polite">
            <template v-if="$cookies.get($config.APP_NAME + 'lang') == 'en'">
              <span style="text-transform: uppercase">S</span>howing {{ meta.from }} 〜 {{ meta.to }} of {{ meta.total }}
              records
            </template>
            <template v-else>
              全{{ meta.total }}件中 {{ meta.from }}件 〜 {{ meta.to }}件 を表示
            </template>
          </div>
        </div>
        <div class="export-options-container">
          <div class="exportOptions">
            <div class="btn-group">
              <div>
                <slot name="header_table"/>
              </div>
            </div>
          </div>
        </div>
        <div class="export-options-container">
          <div class="exportOptions">
            <div class="btn-group">
              <div>
                <slot name="header_right_table"/>
                <button v-if="actions.createEntry" @click="() => actions.createEntry()" class="btn btn-create">
                  {{ $t('common.create') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="dataTables_wrapper no-footer bg-white">
          <div class="table-responsive sm-m-b-15" style="max-height: 300px">
            <table :class="[entries.length ? '' : 'table-empty-data', 'table dataTable app-table no-footer']">
              <thead>
              <tr>
                <template v-for="column in columns">
                  <th v-if="column.sortable" @click="toggleSortBy(column.name)"
                      :class="[
                    pagination.sortBy === column.name ? (pagination.descending ? 'sorting_desc' : 'sorting_asc') : 'sorting',
                     'text-center',
                     column.fitSize ? 'cell-fit-content' : ''
                     ]">
                    {{ column.label }}
                  </th>
                  <th v-else
                      :class="[ 'text-center', column.fitSize ? 'cell-fit-content' : '']">
                    {{ column.label }}
                  </th>
                </template>
                <th class="text-center cell-fit-content" v-if="actions.editEntry">{{
                    $t('common.edit')
                  }}
                </th>
                <th class="text-center cell-fit-content" v-if="actions.deleteEntry || actions.deleteEntryCondition">{{
                    $t('common.delete')
                  }}
                </th>
                <th class="text-center cell-fit-content" v-for="action in actions.others">
                  {{ action.label }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="!entries.length">
                <td colspan="100">
                  <span v-if="loading">
                    {{ $t('common.loading') }}
                  </span>
                  <span v-else>
                    {{ $t('common.list empty') }}
                  </span>
                </td>
              </tr>
              <tr v-for="entry in entries">
                <slot v-for="column in columns" :name="'cell-' + column.name" :row="entry">
                  <td :class="['v-align-middle', column.class]">
                    <p v-if="column.computedHtml" class="app-table-p app-cell-tooltip">
                      <span v-html="column.computedHtml(entry)"></span>
                    </p>
                    <p v-else
                       :data-original-title="column.computed ? column.computed(entry) : _.get(entry, column.name)"
                       class="app-table-p app-cell-tooltip">
                      <span>{{ column.computed ? column.computed(entry) : _.get(entry, column.name) }}</span>
                    </p>
                  </td>
                </slot>
                <td class="v-align-middle" v-if="actions.editEntry">
                  <div @click="() => actions.editEntry(entry)"
                       class="btn-tool edit">
                  </div>
                </td>
                <td class="v-align-middle" v-if="actions.deleteEntry || actions.deleteEntryCondition">
                  <div
                    v-if="!actions.deleteEntryCondition || actions.deleteEntryCondition && actions.deleteEntryCondition(entry) "
                    @click="deleteEntry(entry)" class="btn-tool delete">
                  </div>
                </td>
                <td class="v-align-middle" v-for="action in actions.others">
                  <template v-if="action.showCondition ? action.showCondition(entry) : true">
                    <template v-if="action.click">
                      <span v-html="action.contentHtml(entry)" @click="action.click(entry)"></span>
                    </template>
                    <template v-else-if="action.routerLink">
                      <router-link :to="action.routerLink(entry)">
                        <span v-html="action.contentHtml(entry)"></span>
                      </router-link>
                    </template>
                    <template v-else>
                      <span v-html="action.contentHtml(entry)"></span>
                    </template>
                  </template>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="d-flex justify-content-between">
              <div class="dataTables_paginate app-paginate-container">
                <AppPaginate
                  v-show="parseInt(meta.last_page) > 1"
                  ref="paginate"
                  :page-count="parseInt(meta.last_page)"
                  :page-range="5"
                  :margin-pages="2"
                  :click-handler="clickPagination"
                  prev-text="<"
                  next-text=">"
                  breakViewClass="paginate-break"
                  prev-class="paginate-button-prev"
                  next-class="paginate-button-next"
                  :force-page="parseInt(pagination.currentPage)"
                  :hide-prev-next="true"
                  :page-class="'paginate-button'">
                </AppPaginate>
              </div>
              <div v-show="parseInt(meta.total) > 5"
                   class="d-flex justify-content-start padding-20 form-group dataTable_info_custom">
                <div class="m-r-5 m-t-5 perpage-label"> {{ $t('common.perpage') }}
                </div>
                <div class="form-group">
                  <select class="form-control" @change="changePerPage"
                          v-model="pagination.currentPerPage">
                    <option v-for="perpage in pagination.perPages" :value="perpage">
                      {{ perpage }}
                    </option>
                  </select></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    settingColumns: Array,
    actions: Object,
    otherActions: Object,
    search: {
      type: Object,
      default: {
        searchAll: false,
        reset: false
      }
    },
    filters: {
      type: Object,
      default: {}
    },
    settingApis: {},
    tableName: String,
  },
  data() {
    const columns = this.settingColumns.map(val => {
      return {
        sortable: false,
        editable: true,
        label: val.label === undefined ? val.name.charAt(0).toUpperCase() + val.name.slice(1) : val.label,
        ...val
      }
    })
    return {
      columns: columns,
      entriesRes: {},
      entries: [],
      loading: true,
      pagination: {
        currentPage: 1,
        search: '',
        sortBy: null,
        descending: false,
        currentPerPage: 10,
        perPages: [5, 10, 25, 50, 100, 250, 500],
      },
      meta: {},
      entrySelectedDelete: {},
    }
  },
  mounted() {
  },
  methods: {
    clickPagination(selectPage) {
      if (selectPage !== this.pagination.currentPage) {
        this.setCurrentPage(selectPage)
      }
    },
    changePerPage() {
      this.setCurrentPage(1)
    },
    setCurrentPage(page) {
      this.pagination.currentPage = page;
      if (this.$refs.paginate) {
        this.$refs.paginate.handlePageSelected(page);
      }
      this.getEntries()
    },
    searchHandle() {
      this.setCurrentPage(1)
      this.getEntries()
      this.$emit('searched')
    },
    async getEntries() {
      const {currentPage, search, sortBy, descending, currentPerPage} = this.pagination
      let params = {
        page: currentPage,
        perPage: currentPerPage,
        search: search,
        sortBy: sortBy,
        sortType: descending ? 'desc' : 'asc',
      }
      const filtersClone = (this.filters)
      _.forEach(filtersClone, (filter) => {
        if (filter.value && filter.value.toString().length) {
          params[`filters[${filter.name}][${filter.type}]`] = filter.value
        }
      })
      let endpoint = this.settingApis.list.endpoint;
      let apiParams = this.settingApis.list.params;
      if (!apiParams) {
        apiParams = {}
      }
      const res = await this.$request.get(endpoint, {...params, ...apiParams})
      if (!res.hasErrors()) {
        this.entriesRes = _.cloneDeep(res);
        this.entries = res.data.data.map((entry, index) => {
          return {
            ...entry, no: (index + 1 + (this.pagination.currentPage - 1) * this.pagination.currentPerPage)
          }
        })
        this.meta = res.data.meta
        if (!this.meta.from) {
          this.meta.from = 0;
        }
        if (!this.meta.to) {
          this.meta.to = 0;
        }
      }
      if (this.pagination.currentPage > 1 && this.entries.length === 0) {
        this.setCurrentPage(1)
      }
      this.$nextTick(() => {
        $.each($('.app-cell-tooltip'), (index, el) => {
          if (el.offsetWidth < el.scrollWidth) {
            $(el).tooltip({boundary: 'window'})
          }
        })
      });
      this.loading = false;
    },
    toggleSortBy(columnName) {
      if (this.pagination.sortBy !== columnName) {
        this.pagination.sortBy = columnName
        this.pagination.descending = false;
      } else {
        if (!this.pagination.descending) {
          this.pagination.sortBy = columnName
          this.pagination.descending = true;
        } else {
          this.pagination.sortBy = null
        }
      }
    },
    async resetAllSearch() {
      this.pagination = {
        currentPage: 1,
        search: '',
        sortBy: null,
        descending: false,
        currentPerPage: 10,
        perPages: [5, 10, 25, 50, 100, 250, 500],
      }
      var newFilters = _.cloneDeep(this.filters)
      _.forEach(newFilters, (filter, key) => {
        newFilters[key] = {...filter, value: null}
      })
      await this.$emit('update:filters', newFilters)
      this.getEntries()
      this.$emit('resetAllSearch')
    }
  }
};
</script>
