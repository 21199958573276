<template>
  <tour-select-table
    ref="table"
    :setting-columns="columns"
    :actions="actions"
    :filters.sync="filters"
    :search="search"
    @resetAllSearch="onResetAllSearch"
    @searched="onSearch"
    :setting-apis="apis">
    <template v-slot:cell-name="props">
      <td class="v-align-middle" style="min-width: 350px;">
        <a target="_blank" :href="props.row.public_url"
           class="a-link-cell" data-toggle="tooltip" :title="props.row.name ">
          <p :data-original-title="props.row.name" class="app-table-p app-cell-tooltip">
            {{ props.row.name }}</p>
        </a>
      </td>
    </template>
    <template v-slot:cell-select="props">
      <td class="v-align-middle">
        <button class="btn btn-tool" @click="tourSelectCallback(props.row)">
          {{ $t('booking.select_tour_btn') }}
        </button>
      </td>
    </template>
  </tour-select-table>
</template>
<script>
import TourSelectTable from "@components/admin/bookings/_partials/TourSelectTable";

export default {
  components: {TourSelectTable},
  props: {
    tourSelectCallback: {
      type: Function
    }
  },
  data() {
    return {
      apis: {
        list: {
          endpoint: this.$consts.API.ADMIN.TOUR_ORDER_TEMPLATES,
        },
      },
      actions: {},
      search: {
        reset:true,
      },
      filters: {
        member_name_multi_like: {
          label: this.$t('booking.tour_name'),
          name: 'name',
          type: 'multi_like',
          uiType: 'multi_select',
          ui_options: {
            // tags: true,
            closeOnSelect: true,
          },
        },
      },
      columns: [
        {name: 'id', label: this.$t('common.#'), class: 'min-w-80 text-center'},
        {name: 'name', label: this.$t('tour_order_template.name'), class: 'min-w-100'},
        {name: 'select', label: this.$t('booking.choose_tour')},
      ]
    }
  },
  mounted() {
    this.$request.get(this.$consts.API.ADMIN.TOUR_ORDERS_SELECT_NAME).then(res => {
      if (!res.hasErrors()) {
        this.filters.member_name_multi_like.options = res.data;
        this.filters = _.cloneDeep(this.filters)
      }
    })
  },
  methods: {
    onShow() {
      setTimeout(() => {
        $.each($('.app-cell-tooltip'), (index, el) => {
          if (el.offsetWidth < el.scrollWidth) {
            $(el).tooltip({boundary: 'window'})
          }
        })
      }, 200)
      this.$refs.table.resetAllSearch()
    },
    onResetAllSearch() {
      this.$request.get(this.$consts.API.ADMIN.TOUR_ORDERS_SELECT_NAME).then(res => {
        if (!res.hasErrors()) {
          this.filters.member_name_multi_like.options = res.data;
          this.filters = _.cloneDeep(this.filters)
        }
      })
    },
    onSearch() {
      this.$request.get(this.$consts.API.ADMIN.TOUR_ORDERS_SELECT_NAME).then(res => {
        if (!res.hasErrors()) {
          this.filters.member_name_multi_like.options = res.data;
          this.filters = _.cloneDeep(this.filters)
        }
      })
    }
  }
}
</script>
